.modal {
    top: 5%;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 1rem;
    max-width: 600px;
    outline: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(66, 66, 66, .5);
}