$text-4xl: 36px;
$text-3xl: 30px;
$text-2xl: 24px;
$text-base: 16px;

// Colors
$lightgray: #f5f5f5;
$black: #000;

// todo more base colors, spacing units
