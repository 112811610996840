.modal {
    position: relative;
    background-color:transparent;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 1rem;
    max-width: fit-content;
    outline: none;
    color: white;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(66, 66, 66, .5);
}